import type { GatsbyBrowser } from 'gatsby'
import * as React from 'react'

import { Preloader } from './src/components/Animations/Preloader'
import { Layout, LayoutContexts } from './src/components/Layout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
      <Layout {...props}>
        <Preloader />
        {element}
      </Layout>
  )
}
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <LayoutContexts>{element}</LayoutContexts>
}
