import React from 'react'
import styled from 'styled-components'

import { StyledALink } from '../../Animations/StyledALink/StyledALink'
import SvgAppshackFullLogo from '../../Svgs/LogoSVG/SvgAppshackFullLogo'
import SvgInsta from '../../Svgs/SvgIcons/SvgInsta'
import SvgLinkedIn from '../../Svgs/SvgIcons/SvgLinkedIn'
import NoaFamily from '../../Svgs/SvgLogos/NoaFamily'

const MainContainer = styled.footer`
  background-color: #fff;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1192px;
  margin: 0 auto;
  gap: 24px;
  padding: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`
const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;

    gap: 40px;
    div {
      flex: 1;
    }
  }
`
const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;

    gap: 40px;
    div {
      flex: 1;
    }
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0px;
  }
`
const Logo = styled.div`
  width: 190px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 170px;
  }
`

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  color: #42474a;
  font-size: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
  }
`

const Title = styled.p`
  font-size: 16px;
  font-family: 'Stolzl-Medium', sans-serif;

  line-height: 24px;
  letter-spacing: 0.15px;
`
const Address = styled.p`
  font-size: 12px;
  color: #42474a;

  line-height: 150%;
`
const SocialContainer = styled.div`
  display: flex;

  gap: 24px;

  margin-top: 10px;
`
const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 14px;
    height: 16px;
  }
`

const Footer = () => {
  return (
    <MainContainer>
      <Wrapper>
        <UpperContainer>
          <LogoContainer>
            <Logo>
              <SvgAppshackFullLogo />
            </Logo>
            <a href="https://www.thenorthalliance.com/" target="_blank" rel="noreferrer">
              <NoaFamily />
            </a>
          </LogoContainer>
          <AddressContainer>
            <Title>Stockholm</Title>
            <Address>
              <StyledALink
                $hoverColor="gray3"
                href="https://www.google.com/maps/place/Tulegatan+13,+113+53+Stockholm/@59.3422701,18.05983,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d6902a2b6f9:0x797a6b5883c4b86f!8m2!3d59.3422674!4d18.0620187!16s%2Fg%2F11cskmgjkw"
                target="_blank"
                rel="noreferrer"
              >
                Tulegatan 13
                <br />
                113 53 Sweden
              </StyledALink>
            </Address>
          </AddressContainer>
          <AddressContainer>
            <Title>Uppsala</Title>
            <Address>
              <StyledALink
                $hoverColor="gray3"
                href="https://www.google.com/maps/place/App+Shack/@59.859936,17.6376523,17z/data=!3m1!4b1!4m6!3m5!1s0x465fcbf294000001:0xdfb1d59ac575c754!8m2!3d59.859936!4d17.639841!16s%2Fg%2F11bbrgfnyw"
                target="_blank"
                rel="noreferrer"
              >
                Dragarbrunnsgatan 36C
                <br />
                753 20 Sweden
              </StyledALink>
            </Address>
          </AddressContainer>
          <AddressContainer>
            <Title>Oslo</Title>
            <Address>
              <StyledALink
                $hoverColor="gray3"
                href="https://www.google.com/maps/place/Nedre+Vollgate+11,+0158+Oslo,+Norway/@59.9115818,10.7356164,17z/data=!3m1!4b1!4m6!3m5!1s0x46416e87960e0a57:0xa60efa6cc58184fb!8m2!3d59.9115791!4d10.7381913!16s%2Fg%2F11c2397n8t?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                Nedre Vollgate 11
                <br />
                0158 Oslo
              </StyledALink>
            </Address>
          </AddressContainer>
        </UpperContainer>
        <LowerContainer>
          <AddressContainer>
            <StyledALink $hoverColor="gray3" href="mailto:contact@appshack.se">
              contact@appshack.se
            </StyledALink>
            <StyledALink $hoverColor="gray3" href="tel://+46731456830">
              +46 73-145 68 30
            </StyledALink>
            <SocialContainer>
              <Link
                href="https://se.linkedin.com/company/app-shack"
                aria-label="Connect with App Shack on LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <SvgLinkedIn />
              </Link>
              <Link
                href="https://www.instagram.com/app_shack/"
                aria-label="Follow App Shack on Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <SvgInsta />
              </Link>
            </SocialContainer>
          </AddressContainer>
        </LowerContainer>
      </Wrapper>
    </MainContainer>
  )
}

export default Footer
