import { motion } from 'framer-motion'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import usePath from '../../../hooks/usePath'
import useWorkshopStore from '../../../store/workshopStore/workshopStore'
import Burger from '../HamburgerMenu/Burger/Burger'
import MenuDesktop from '../HamburgerMenu/Menu/MenuDesktop'
import MenuMobile from '../HamburgerMenu/Menu/MenuMobile'
import LogoSVG from '../../Svgs/LogoSVG/LogoSVG'
import { theme } from '../../../themes'

interface Props {
  scrollProgress: number
  windowSizes: { width: number; height: number }
  isLandingPage: boolean | undefined
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  t: any
}

const StyledHeader = styled(motion.header)<{ backgroundcolor?: string }>`
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.backgroundcolor};
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 30px 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 36px 40px;
  }
`

const NavBar = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BurgerMenu = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const SvgContainer = styled.div``

const Header = (props: Props) => {
  const { scrollProgress, windowSizes, isLandingPage, open, setOpen, t } = props
  const { width: windowWidth, height: windowHeight } = windowSizes
  const [nrOfClicks, setNrOfClicks] = useState(0)
  const menuId = 'main-menu'
  const path = usePath()

  const [setTrue] = useWorkshopStore((state) => [state.setTrue])

  useEffect(() => {
    if (nrOfClicks > 9 && nrOfClicks % 10 === 0) {
      setTrue()
    }
  }, [nrOfClicks])

  const isHomePage = useMemo(() => {
    return !path || path === '/'
  }, [path])

  const hasScrolledPastHero = scrollProgress - windowHeight > 0

  const tabletWidth = theme.breakpoints.tablet as string
  const tablet = parseInt(tabletWidth.replace('px', ''))
  const contentWidth = 1200
  const isSmallScreen = windowWidth < tablet
  const isContentWidth = windowWidth > contentWidth
  const hasBackground = (!isContentWidth || open) && (hasScrolledPastHero || (open && !isSmallScreen) || !isLandingPage)

  return (
    <StyledHeader backgroundcolor={hasBackground ? 'rgba(11,19,22,1)' : 'rgba(11,19,22,0)'}>
      <NavBar>
        <AnchorLink to="/#heroSection" onAnchorLinkClick={() => !isHomePage && setOpen(false)} stripHash>
          <SvgContainer onClick={() => setNrOfClicks((prev) => prev + 1)}>
            <LogoSVG />
          </SvgContainer>
        </AnchorLink>
        <BurgerMenu>
          {isSmallScreen ? (
            <MenuMobile t={t} $isHomePage={isHomePage} open={open} setOpen={setOpen} id={menuId} />
          ) : (
            <MenuDesktop t={t} $isHomePage={isHomePage} open={open} setOpen={setOpen} id={menuId} />
          )}
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
        </BurgerMenu>
      </NavBar>
    </StyledHeader>
  )
}

export default Header
